<template>
  <div class="flex flex-col sm:flex-row mb-1 gap-1 sm:gap-2 items-start sm:items-center">
    <div class="w-full sm:w-auto"
         v-if="!onlyRegionFilter">
      <div class="absolute z-30 left-0 sm:left-auto p-0 bg-theme-primary -mt-[13px] -ml-[9px] w-screen sm:w-[500px] sm:shadow-2xl"
           :class="{ hidden: !filterBarVisible }">
        <FilterBar ref="filterBar"
                   :regionId="store.regionId"
                   @submit="applyFilters"
                   @close="closeFilterBar"
                   class="w-full" />
      </div>
      <ButtonBright class="w-full"
                    @click="openFilterBar()"
                    :class="filtersSet ? 'bg-theme-secondary text-white' : ''">{{
                      $t("spots.filter")
                    }}<span v-if="filtersSet"
              class="inline-block bg-theme-primary h-[20px] w-[20px] rounded-[10px] ml-1 text-black">{{
                numberOfFilters
              }}</span>
      </ButtonBright>
    </div>
    <div>
      <RegionFilterBar v-model="store.regionId"
                       @change="store.loadInitalSpotPreviews(true)" />
    </div>
    <div class="flex grow justify-end">
      <ListSortBar v-if="!onlyRegionFilter"
                   v-model="store.sort"
                   @change="store.loadInitalSpotPreviews(true)" />
    </div>
  </div>
  <ErrorMessage v-if="store.initialError"
                :message="store.initialError" />
  <KeepAlive v-else>
    <div class="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xs:gap-1 sm:gap-2 lg:gap-3">
      <SpotPreview v-if="store.spotPreviews"
                   v-for="spotPreview in store.spotPreviews"
                   :spotPreview="spotPreview"
                   class="py-1 text-theme-secondary" />
    </div>
  </KeepAlive>
  <ErrorMessage v-if="store.error"
                :message="store.error" />
  <div class="mt-3 mb-4 flex justify-center">
    <ButtonPrimary v-if="!store.loading && !store.lastPage && !store.initialError && !store.error"
                   @click="loadMore()">{{ $t("spots.load_more") }}
    </ButtonPrimary>
    <LoadingSpinner v-if="store.loading" />
    <div v-if="!store.loading && !store.initialError && !store.error && store.spotPreviews && store.spotPreviews.length == 0"
         class="text-3xl m-3">{{ $t('spots.no_spots') }}
    </div>
  </div>
</template>
<script setup lang="ts">

const props = defineProps<{
  onlyRegionFilter?: Boolean,
  pageSize?: number
}>();

import { useSpotlistStore } from "~/store/spotlist";

const platformSettings = usePlatformSettings();
const filtersSet = ref<boolean>(false);
const numberOfFilters = ref<number>(0);
const filterBar = ref();
const pageSize = props.pageSize || 18;

const store = useSpotlistStore();
store.pagesize = pageSize;
store.loadInitalSpotPreviews();

const filterBarVisible = ref(false);

onMounted(() => {
  adjustFilterButton();
});

function loadMore() {
  store.loadMore();
}

function applyFilters() {
  const filterParams = platformSettings.getFilterParams();
  filterBarVisible.value = false;
  store.loadInitalSpotPreviews(true);
  adjustFilterButton();
}

function adjustFilterButton() {
  const filterParams = platformSettings.getFilterParams();
  if (filterParams['regionId'] != null) {
    filtersSet.value = Object.keys(filterParams).length > 1
    numberOfFilters.value = Object.keys(filterParams).length - 1
  } else {
    filtersSet.value = Object.keys(filterParams).length > 0
    numberOfFilters.value = Object.keys(filterParams).length
  }
}

function closeFilterBar() {
  filterBar.value.setFilters();
  filterBarVisible.value = false;
}

function openFilterBar() {
  filterBarVisible.value = true;
}

</script>
